import { GUARDIAN_SEARCH_DEFAULT_PAGE_SIZE, GUARDIAN_SEARCH_MAX_PAGE_SIZE } from "./../constants/CommunityConstants";
import { create } from "zustand";
import { getErrorFromResponse, jqXHR, ResponseError } from "../types/Api";
import { BaseGuardian, Guardian, PurgeScope, Student } from "../types/Community";
import Api from "../utils/Api";
import { useSessionStore } from "./SessionStore";
import FileSaver from "file-saver";

export const userDetailsURL = (userIdOrUsername: string): string => {
  const device = useSessionStore.getState().getDevice();
  return `/cadm/v1/appliances/${device.id}/students/${userIdOrUsername}`;
};

export const userGuardiansURL = (userIdOrUsername: string): string => {
  const device = useSessionStore.getState().getDevice();
  return `/cadm/v1/appliances/${device.id}/students/${userIdOrUsername}/guardians`;
};

export const connectionsURL = (userIdOrUsername: string): string => {
  const device = useSessionStore.getState().getDevice();
  return `/cadm/v1/appliances/${device.id}/students/${userIdOrUsername}/claims`;
};

export const exportGuardiansURL = (): string => {
  const device = useSessionStore.getState().getDevice();
  return `/cadm/v1/appliances/${device.id}/guardians/export`;
};

export const guardianSearchURL = (): string => {
  const device = useSessionStore.getState().getDevice();
  return `/cadm/v1/appliances/${device.id}/guardians/search`;
};

export const guardianPurgeURL = (): string => {
  const device = useSessionStore.getState().getDevice();
  return `/cadm/v1/appliances/${device.id}/guardians/purge`;
};

export const guardiansURL = (): string => {
  const device = useSessionStore.getState().getDevice();
  return `/cadm/v1/appliances/${device.id}/guardians`;
};

export const guardianDetailsURL = (guardianIdOrEmail: string): string => {
  const device = useSessionStore.getState().getDevice();
  return `/cadm/v1/appliances/${device.id}/guardians/${guardianIdOrEmail}`;
};

export interface SearchOptions {
  term?: string;
  page?: number;
  details?: boolean;
  pageSize?: number;
}

export interface PurgeOptions {
  scope: PurgeScope;
}

export interface GuardianStore {
  guardians: Guardian[];
  hasNextPage: boolean;
  search: (options: SearchOptions) => Promise<Guardian[]>;
  downloadAllGuardians: () => Promise<void>;
  fetchGuardianDetails: (guardianId: string) => Promise<Guardian>;
  fetchUserGuardians: (userId: string) => Promise<Guardian[]>;
  addUserGuardian: (userId: string, guardian: BaseGuardian) => Promise<Guardian>;
  removeUserGuardian: (userId: string, guardian: Guardian) => Promise<Guardian>;
  disconnectGuardian: (userId: string, guardian: Guardian) => Promise<Guardian>;
  updateGuardian: (guardianId: string, guardian: Guardian) => Promise<Guardian>;
  purgeGuardians: (options: PurgeOptions) => Promise<void>;
  setGuardian: (guardian: Guardian) => void;
  reset: () => void;
}

export const useGuardianStore = create<GuardianStore>((set, get) => ({
  guardians: [],
  hasNextPage: true,

  search: async (options: SearchOptions): Promise<Guardian[]> => {
    const pageNumber = options.page ?? 0;
    const pageSize = options.pageSize ? Math.min(options.pageSize, GUARDIAN_SEARCH_MAX_PAGE_SIZE) : GUARDIAN_SEARCH_DEFAULT_PAGE_SIZE;

    const params = new URLSearchParams({ page: String(pageNumber), limit: String(pageSize) });

    if (options.term) {
      params.append("q", options.term);
    }

    let guardians: Guardian[] = [],
      hasNextPage = true,
      statusCode = 200;

    // eslint-disable-next-line
    await Api.getAsync(`${guardianSearchURL()}?${params.toString()}`).then(
      (response: { guardians: Guardian[] }) => {
        hasNextPage = pageSize === response.guardians.length;

        if (pageNumber > 0) {
          guardians = [...get().guardians, ...response.guardians];
        } else {
          guardians = response.guardians;
        }
      },
      (reason: jqXHR) => (statusCode = reason?.status)
    );

    if (statusCode !== 200) {
      throw new ResponseError("Failed to fetch guardians", statusCode);
    }

    set({ guardians, hasNextPage });

    return guardians;
  },

  downloadAllGuardians: async (): Promise<void> => {
    const device = useSessionStore.getState().getDevice();

    const response = await fetch(exportGuardiansURL(), {
      headers: {
        Accept: "text/csv",
        Token: useSessionStore.getState().getToken(),
      },
    });

    if (!response.ok) {
      throw new ResponseError("Failed to export parent data", response.status);
    }

    FileSaver.saveAs(await response.blob(), `${device.id}_parent_data.csv`);
  },

  fetchUserGuardians: async (userId: string): Promise<Guardian[]> => {
    let guardians: Guardian[] = [],
      statusCode = 200;

    await Api.getAsync(userDetailsURL(userId)).then(
      (response: { guardians: Guardian[] }) => {
        guardians = response.guardians;
      },
      (reason: jqXHR) => (statusCode = reason?.status)
    );

    if (statusCode !== 200) {
      throw new ResponseError("Failed to fetch parents", statusCode);
    }

    return guardians;
  },

  fetchGuardianDetails: async (guardianId: string): Promise<Guardian> => {
    let guardian: Guardian | undefined,
      statusCode = 200;

    await Api.getAsync(guardianDetailsURL(guardianId)).then(
      (response: { guardian: Guardian; students: Student[] }) => {
        guardian = response.guardian;
        guardian.students = response.students;
      },
      (reason: jqXHR) => (statusCode = reason?.status)
    );

    if (!guardian || statusCode !== 200) {
      throw new ResponseError("Failed to fetch parent", statusCode);
    }

    return guardian;
  },

  addUserGuardian: async (userId: string, guardian: BaseGuardian): Promise<Guardian> => {
    let saved: Guardian | undefined,
      errorMessage: string | undefined,
      statusCode = 200;

    await Api.postAsync(userGuardiansURL(userId), { guardians: [guardian] }).then(
      (response: { guardians: Guardian[] }) => {
        saved = response.guardians[0];
        saved.claimed = false;
        saved.delegated = false;
      },
      (reason: jqXHR) => {
        statusCode = reason?.status;
        errorMessage = getErrorFromResponse(reason);
      }
    );

    if (!saved || statusCode !== 200) {
      if (Math.floor(statusCode / 100) === 4 && errorMessage) {
        throw new ResponseError(`Failed to add parent: ${errorMessage}`, statusCode);
      }
      throw new ResponseError("Failed to add parent", statusCode);
    }

    return saved;
  },

  removeUserGuardian: async (userId: string, guardian: Guardian): Promise<Guardian> => {
    let saved: Guardian | undefined,
      statusCode = 204;

    await Api.deleteAsync(userGuardiansURL(userId), { guardians: [guardian] }).then(
      () => {
        saved = { ...guardian };

        if (guardian.students?.length) {
          saved.students = guardian.students.filter((s) => s.id !== userId && s.username !== userId);
          saved.linkCount = saved.students.length;
          saved.claimCount = saved.students?.filter((s) => s.claimed).length;
          saved.delegatedCount = saved.students?.filter((s) => s.delegated).length;
        }
      },
      (reason: jqXHR) => (statusCode = reason?.status)
    );

    if (!saved || statusCode !== 204) {
      throw new ResponseError("Failed to remove parent", statusCode);
    }

    return saved;
  },

  disconnectGuardian: async (userId: string, guardian: Guardian): Promise<Guardian> => {
    let saved: Guardian | undefined,
      statusCode = 204;

    await Api.deleteAsync(connectionsURL(userId), { guardians: [guardian] }).then(
      () => {
        saved = { ...guardian };
        saved.claimed = false;
        saved.delegated = false;

        if (guardian.students?.length) {
          saved.students = guardian.students.map((s) => {
            if (s.id === userId || s.username === userId) {
              return { ...s, claimed: false, delegated: false };
            }
            return s;
          });
          saved.linkCount = saved.students.length;
          saved.claimCount = saved.students?.filter((s) => s.claimed).length;
          saved.delegatedCount = saved.students?.filter((s) => s.delegated).length;
        }
      },
      (reason: jqXHR) => (statusCode = reason?.status)
    );

    if (!saved || statusCode !== 204) {
      throw new ResponseError("Failed to disconnect parent", statusCode);
    }

    return saved;
  },

  updateGuardian: async (guardianId: string, guardian: Guardian): Promise<Guardian> => {
    let saved: Guardian | undefined,
      errorMessage: string | undefined,
      statusCode = 200;

    await Api.putAsync(guardianDetailsURL(guardianId), guardian).then(
      (response: Guardian) => {
        saved = response;
        // copy over fields not modified/returned
        saved.claimed = guardian.claimed;
        saved.delegated = guardian.delegated;
        saved.linkCount = guardian.linkCount;
        saved.claimCount = guardian.claimCount;
        saved.delegatedCount = guardian.delegatedCount;
      },
      (reason: jqXHR) => {
        statusCode = reason?.status;
        errorMessage = getErrorFromResponse(reason);
      }
    );

    if (!saved || statusCode !== 200) {
      if (Math.floor(statusCode / 100) === 4 && errorMessage) {
        throw new ResponseError(`Failed to update parent: ${errorMessage}`, statusCode);
      }
      throw new ResponseError("Failed to update parent", statusCode);
    }

    return saved;
  },

  purgeGuardians: async (options: PurgeOptions): Promise<void> => {
    let errorMessage: string | undefined,
      statusCode = 204;

    const params = new URLSearchParams({ scope: options.scope });

    await Api.deleteAsync(`${guardianPurgeURL()}?${params.toString()}`).then(
      () => ({}),
      (reason: jqXHR) => {
        statusCode = reason?.status;
        errorMessage = getErrorFromResponse(reason);
      }
    );

    if (statusCode !== 204) {
      if (Math.floor(statusCode / 100) === 4 && errorMessage) {
        throw new ResponseError(`Failed to purge parents: ${errorMessage}`, statusCode);
      }
      throw new ResponseError("Failed to purge parents", statusCode);
    }
  },

  setGuardian: (guardian: Guardian) => {
    const guardians: Guardian[] = [];

    for (const g of get().guardians) {
      if (g.id === guardian.id) {
        guardians.push(guardian);
      } else {
        guardians.push(g);
      }
    }

    set({ guardians });
  },

  reset: () => {
    set({ guardians: [], hasNextPage: true });
  },
}));
