import React from "react";

import LeftPanel from "../../utils/LeftPanel";
import LetterPanel from "../../modules/LetterPanel";
import LetterPanelHeader from "../../modules/LetterPanelHeader";
import LetterPanelBody from "../../modules/LetterPanelBody";
import LetterPanelSaveButton from "../../modules/LetterPanelSaveButton";
import LetterPanelFooter from "../../modules/LetterPanelFooter";
import DumbBusyIndicator from "../../modules/DumbBusyIndicator";
import SimpleLayer7CriteriaSelector from "../../modules/SimpleLayer7CriteriaSelector";
import Api from "../../utils/Api";
import SearchableSelect from "../../modules/SearchableSelect";
import SmartListSelector from "../../modules/SmartListSelector";
import ObjectStore from "../../stores/ObjectStore";
import ObjectActions from "../../actions/ObjectActions";
import { cy } from "../../utils/CypressUtil";
import { MultiGroupSearchSelector } from "../GroupSearch/MultiGroupSearchSelector";
import { useFeatureFlagStore } from "../../storez/FeatureFlagStore";

export default class MobileAgentConfiguration extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      on_network: {
        filtering_enabled: false,
        default_filtering_mode: "web",
        filtering_groups: {
          bypass: [],
          dns: [],
          web: [],
          full: [],
          companion: [],
        },
        mitm_enabled: false,
        mitm_inclusions: {
          websites: [],
          signatures: [],
          website_objects: [],
        },
        mitm_exclude_groups: [],
        safe_search: {
          bing: {
            enabled: false,
          },
          google: {
            enabled: false,
          },
          youtube: {
            enabled: false,
            mode: "MODERATE",
          },
        },
        classroom_enabled: false,
      },

      off_network: {
        filtering_enabled: false,
        default_filtering_mode: "web",
        filtering_groups: {
          bypass: [],
          dns: [],
          web: [],
          full: [],
          companion: [],
        },
        mitm_enabled: false,
        mitm_inclusions: {
          websites: [],
          signatures: [],
          website_objects: [],
        },
        mitm_exclude_groups: [],
        safe_search: {
          bing: {
            enabled: false,
          },
          google: {
            enabled: false,
          },
          youtube: {
            enabled: false,
            mode: "MODERATE",
          },
        },
        classroom_enabled: false,
      },

      featureFlags: useFeatureFlagStore.getState().flags ?? {},

      // Domain objects array
      exclude_domains: [],
      loaded: false,
      saving: false,
      changes: false,

      on_network_config_valid: true,
      off_network_config_valid: true,

      availableWebsiteObjects: ObjectStore.getWebsiteObjects(),
      availableDomainsObjects: ObjectStore.getDomainsObjects(),
      support_admin: localStorage.getItem("SUPPORTADMIN") === "true",
      auto_set_policies: {
        companion: {
          enabled: false,
        },
        content_aware: {
          enabled: false,
        },
      },
    };
  }

  componentDidMount() {
    useFeatureFlagStore
      .getState()
      .getOrFetch()
      .then((flags) => {
        this.setState((state) => ({ ...state, featureFlags: flags }));
      });

    this.handle_load();
    ObjectStore.listen(this.onChange);

    setTimeout(() => {
      ObjectActions.fetch();
    }, 0);
  }

  componentWillUnmount() {
    ObjectStore.unlisten(this.onChange);
  }

  onChange = () => {
    const newState = Object.assign({}, this.state);
    newState.availableWebsiteObjects = ObjectStore.getWebsiteObjects();
    newState.availableDomainsObjects = ObjectStore.getDomainsObjects();
    this.setState(newState);
  };

  render() {
    return (
      <LeftPanel>
        <LetterPanel>
          <LetterPanelHeader>
            Mobile Agent
            <DumbBusyIndicator loaded={this.state.loaded} />
          </LetterPanelHeader>
          <LetterPanelBody>
            <form className="mui-form">
              {this.render__NetworkConfiguration("on_network", "On School Manager Network")}
              {this.render__NetworkConfiguration("off_network", "Off School Manager Network")}
              {this.render__ExcludeDomains()}
            </form>
          </LetterPanelBody>
          <LetterPanelFooter>
            <LetterPanelSaveButton onClick={this.handle_Submit} changes={this.state.changes} saving={this.state.saving} />
          </LetterPanelFooter>
        </LetterPanel>
      </LeftPanel>
    );
  }

  render__NetworkConfiguration = (network, title) => {
    return (
      <div>
        <div className="formgroup_title">
          <div className="formgroup_title_title">{title}</div>
        </div>
        <div className="formgroup-content">
          <div className="formgroup-element">
            <div className="formgroup-element-title">Filtering Enabled</div>
            <div className="formgroup-element-fields">
              <div className="mui-checkbox">
                <input
                  type="checkbox"
                  data-cy={"filtering_" + cy(network)}
                  checked={this.state[network].filtering_enabled}
                  onChange={(e) => this.handle_ChangeFilteringEnabled(network, e)}
                />
              </div>
            </div>
          </div>

          {this.renderOptions(network)}
          {this.render_classroom_config(network)}
        </div>
      </div>
    );
  };

  renderOptions = (network) => {
    if (this.state[network].filtering_enabled) {
      const valueDataMap = [
        ["dns", "DNS"],
        ["web", "WEB"],
      ];

      const companionModeEnabled = this.state.featureFlags["companion-mode-enabled"] ?? false;
      if (companionModeEnabled) {
        valueDataMap.push(["companion", "COMPANION"]);
      }

      //Full mode is only visible for support admins or its already enabled
      const shouldEnableFullMode = this.state.support_admin || this.state[network].default_filtering_mode === "full";
      if (shouldEnableFullMode) {
        valueDataMap.push(["full", "FULL"]);
      }

      return (
        <div>
          <div className="mui-select">
            <div className="formgroup-element">
              <div className="formgroup-element-title">Default Filtering Mode</div>
              <div className="formgroup-element-fields">
                <SearchableSelect
                  data-cy={"default_filtering_mode_" + cy(network)}
                  value={this.state[network].default_filtering_mode}
                  clearable={false}
                  onChange={(e) => this.handle_ChangeDefaultFilteringMode(network, e)}
                  valueDataMap={valueDataMap}
                ></SearchableSelect>
              </div>
            </div>
          </div>

          {this.state.featureFlags.config_agents_filtering_bypass &&
            this.render__FilteringGroups(network, "BYPASS Filtering Groups", "bypass")}

          {this.render__FilteringGroups(network, "DNS Filtering Groups", "dns")}
          {this.render__FilteringGroups(network, "WEB Filtering Groups", "web")}

          {shouldEnableFullMode && this.render__FilteringGroups(network, "FULL Filtering Groups", "full")}

          {companionModeEnabled && this.render__FilteringGroups(network, "COMPANION Filtering Groups", "companion")}

          <div className="formgroup-element">
            <div className="formgroup-element-title">MITM Enabled</div>
            <div className="formgroup-element-fields">
              <div className="mui-checkbox">
                <input
                  type="checkbox"
                  data-cy={"MITM_" + cy(network)}
                  checked={this.state[network].mitm_enabled}
                  onChange={(e) => this.handle_ChangeMitmEnabled(network, e)}
                />
              </div>
            </div>
          </div>

          {this.render__MitmOptions(network)}
          {this.render_safeSearchOptions(network)}
        </div>
      );
    }
  };

  render_safeSearchOptions = (network) => {
    if (this.state[network].safe_search) {
      return (
        <div>
          <div className="formgroup_title">
            <div className="formgroup_small_subtitle">Safe Search</div>
          </div>
          <div className="formgroup-element">
            <div className="formgroup-element-title">Google</div>
            <div className="formgroup-element-fields">
              <div className="mui-checkbox">
                <input
                  type="checkbox"
                  data-cy={"google_" + cy(network)}
                  checked={this.state[network].safe_search.google.enabled}
                  onChange={(e) => this.handle_ChangeGoogleSafeSearch(network, e)}
                />
              </div>
            </div>
          </div>
          <div className="formgroup-element">
            <div className="formgroup-element-title">Bing</div>
            <div className="formgroup-element-fields">
              <div className="mui-checkbox">
                <input
                  type="checkbox"
                  data-cy={"bing__" + cy(network)}
                  checked={this.state[network].safe_search.bing.enabled}
                  onChange={(e) => this.handle_ChangeBingSafeSearch(network, e)}
                />
              </div>
            </div>
          </div>
          <div className="formgroup-element">
            <div className="formgroup-element-title">Youtube</div>
            <div className="formgroup-element-fields">
              <div className="mui-checkbox">
                <input
                  type="checkbox"
                  data-cy={"youtube_" + cy(network)}
                  checked={this.state[network].safe_search.youtube.enabled}
                  onChange={(e) => this.handle_ChangeYoutubeSafeSearch(network, e)}
                />
              </div>
              {this.render_youtube_options(network)}
            </div>
          </div>
        </div>
      );
    }
  };

  render_youtube_options = (network) => {
    if (this.state[network].safe_search && this.state[network].safe_search.youtube.enabled) {
      return (
        <div className="mui-select">
          <div className="formgroup-element">
            <div className="formgroup-element-title">Mode</div>
            <div className="formgroup-element-fields">
              <SearchableSelect
                value={this.state[network].safe_search.youtube.mode || "MODERATE"}
                clearable={false}
                onChange={(e) => this.handle_ChangeYoutubeSafeSearchMode(network, e)}
                valueDataMap={[
                  ["MODERATE", "MODERATE"],
                  ["STRICT", "STRICT"],
                ]}
                data-cy={"safeSearchMode_" + cy(network)}
              ></SearchableSelect>
            </div>
          </div>
        </div>
      );
    }
  };

  getObjectId = (item) => {
    return item["id"];
  };

  getObjectName = (item) => {
    return item["name"];
  };

  render__ExcludeDomains = () => {
    return (
      <div>
        <div className="formgroup_title">
          <div className="formgroup_title_title">DNS Filtering</div>
        </div>
        <div className="formgroup-content">
          <div className="formgroup-element">
            <div className="formgroup-element-title">Exclude Domains</div>
            <div className="formgroup-element-fields">
              <SmartListSelector
                get__value={this.getObjectId}
                get__label={this.getObjectName}
                selected={this.state.exclude_domains}
                available={this.state.availableDomainsObjects}
                removeSelectedFromOptions
                addElement={this.handle_AddExcludeDomains}
                removeElement={this.handle_RemoveExcludeDomains}
              />
            </div>
          </div>
        </div>
      </div>
    );
  };
  render__FilteringGroups = (network, title, mode) => {
    return (
      <div>
        <div className="formgroup-element">
          <div className="formgroup-element-title">{title}</div>
          <div className="formgroup-element-fields">
            <MultiGroupSearchSelector
              preselected={this.state[network].filtering_groups[mode].map((group) => ({ value: group, name: group }))}
              onChangeGroups={(e) => this.handle_ChangedFilteringGroup(network, mode, e)}
              useLegacyId={true}
            />
          </div>
        </div>
      </div>
    );
  };

  render__MitmOptions = (network) => {
    let allowed_signatures = [
      "sphirewall.application.googlesearch",
      "sphirewall.application.bing",
      "sphirewall.application.youtube",
      "sphirewall.application.vimeo",
    ];
    if (this.state[network].mitm_enabled) {
      let id = `${network}_inspected_signatures`;
      return (
        <div>
          <div className="formgroup-element">
            <div className="formgroup-element-title" data-cy="inspectedSelector">
              Inspected
            </div>
            <div className="formgroup-element-fields">
              <SimpleLayer7CriteriaSelector
                placeholder={"Search for Website/Category"}
                allowed_signatures={allowed_signatures}
                selected_websites={this.state[network].mitm_inclusions.websites}
                selected_signatures={this.state[network].mitm_inclusions.signatures}
                selected_website_objects={this.state[network].mitm_inclusions.website_objects}
                handle_BubbleChanges={(e) => this.handle_ModifyInspected(network, e)}
                id={id}
                data-cy={this.props["data-cy"]}
              />
              {this.render__Error(network)}
            </div>
          </div>

          <div className="formgroup-element">
            <div className="formgroup-element-title">MITM Exclude Groups</div>
            <div className="formgroup-element-fields">
              <MultiGroupSearchSelector
                preselected={this.state[network].mitm_exclude_groups.map((group) => ({ value: group, name: group })) ?? []}
                onChangeGroups={(e) => this.handle_ChangedMitmExcludeGroups(network, e)}
                useLegacyId={true}
              />
            </div>
          </div>
        </div>
      );
    }
  };

  render_classroom_config = (network) => {
    return (
      <div>
        <div className="formgroup_title">
          <div className="formgroup_small_subtitle">Classwize</div>
        </div>
        <div className="formgroup-element">
          <div className="formgroup-element-title">Classroom Enabled</div>
          <div className="formgroup-element-fields">
            <div className="mui-checkbox">
              <input
                type="checkbox"
                data-cy={"classroom_" + cy(network)}
                checked={this.state[network].classroom_enabled}
                onChange={(e) => this.handle_ChangeClassroomEnabled(network, e)}
              />
            </div>
          </div>
        </div>
      </div>
    );
  };

  render__Error = (network) => {
    let error_msg = "Must select atleast one Website/Category";

    if (
      (network === "on_network" && !this.state.on_network_config_valid) ||
      (network === "off_network" && !this.state.off_network_config_valid)
    ) {
      return <div className={"errorMessage"}>{error_msg}</div>;
    }
  };

  handle_load = () => {
    Api.get("/config/ajax/fzagentconfig", (result) => {
      // Check old config first
      const initState = Object.assign({}, this.state);
      if (result["mitm_enabled"]) {
        initState.on_network.mitm_enabled = true;
        initState.off_network.mitm_enabled = true;
      }
      if (result["mitm_disabled_outside_network_enabled"]) {
        initState.off_network.mitm_enabled = false;
      }
      if (result["mitm_groups"]) {
        initState.on_network.filtering_groups.web = result["mitm_groups"];
      }
      if (result["mitm_disabled_outside_network_groups"]) {
        initState.off_network.filtering_groups.web = result["mitm_disabled_outside_network_groups"];
      }
      // Check new config next
      if (result["on_network"]) {
        initState.on_network = result["on_network"];

        if (!initState.on_network.safe_search) {
          initState.on_network.safe_search = this.default_safe_search_config();
        }

        if (!initState.on_network.filtering_groups.bypass) {
          initState.on_network.filtering_groups.bypass = [];
        }

        if (!initState.on_network.filtering_groups.companion) {
          initState.on_network.filtering_groups.companion = [];
        }
      }
      if (result["off_network"]) {
        initState.off_network = result["off_network"];

        if (!initState.off_network.safe_search) {
          initState.off_network.safe_search = this.default_safe_search_config();
        }

        if (!initState.off_network.filtering_groups.bypass) {
          initState.off_network.filtering_groups.bypass = [];
        }

        if (!initState.off_network.filtering_groups.companion) {
          initState.off_network.filtering_groups.companion = [];
        }
      }
      if (!!result["exclude_domains"]) {
        initState.exclude_domains = result["exclude_domains"];
      } else {
        initState.exclude_domains = [];
      }
      if (!!result["auto_set_policies"]) {
        initState.auto_set_policies = result["auto_set_policies"];
      }
      initState.loaded = true;
      this.setState(initState);
    });
  };

  default_safe_search_config = () => {
    return {
      google: {
        enabled: false,
      },
      bing: {
        enabled: false,
      },
      youtube: {
        enabled: false,
      },
    };
  };

  handle_ChangeFilteringEnabled = (network, event) => {
    const newState = Object.assign({}, this.state);
    newState[network].filtering_enabled = event.target.checked;
    this.setState(newState);
    this.notifyChanged();
  };

  handle_ChangeDefaultFilteringMode = (network, event) => {
    const newState = Object.assign({}, this.state);
    newState[network].default_filtering_mode = event.value;
    this.setState(newState);
    this.notifyChanged();
  };

  handle_ChangeMitmEnabled = (network, event) => {
    const newState = Object.assign({}, this.state);
    newState[network].mitm_enabled = event.target.checked;
    this.setState(newState);
    this.notifyChanged();
  };

  handle_ChangeGoogleSafeSearch = (network, event) => {
    const newState = Object.assign({}, this.state);
    newState[network].safe_search.google.enabled = event.target.checked;
    this.setState(newState);
    this.notifyChanged();
  };

  handle_ChangeBingSafeSearch = (network, event) => {
    const newState = Object.assign({}, this.state);
    newState[network].safe_search.bing.enabled = event.target.checked;
    this.setState(newState);
    this.notifyChanged();
  };

  handle_ChangeYoutubeSafeSearch = (network, event) => {
    const newState = Object.assign({}, this.state);
    newState[network].safe_search.youtube.enabled = event.target.checked;
    this.setState(newState);
    this.notifyChanged();
  };

  handle_ChangeYoutubeSafeSearchMode = (network, event) => {
    const newState = Object.assign({}, this.state);
    newState[network].safe_search.youtube.mode = event.value;
    this.setState(newState);
    this.notifyChanged();
  };

  handle_ChangeClassroomEnabled = (network, event) => {
    const newState = Object.assign({}, this.state);
    newState[network].classroom_enabled = event.target.checked;
    this.setState(newState);
    this.notifyChanged();
  };

  notifyChanged = () => {
    this.setState({ changes: true });
  };

  handle_AddExcludeDomains = (item) => {
    const newState = Object.assign({}, this.state);
    newState.exclude_domains.push(item);
    this.setState(newState);
    this.notifyChanged();
  };

  handle_RemoveExcludeDomains = (item) => {
    const newState = Object.assign({}, this.state);
    const index = newState.exclude_domains.indexOf(item);
    if (index > -1) {
      newState.exclude_domains.splice(index, 1);
      this.setState(newState);
      this.notifyChanged();
    }
  };

  handle_ChangedFilteringGroup = (network, mode, groups) => {
    this.setState({
      ...this.state,
      [network]: {
        ...this.state[network],
        filtering_groups: { ...this.state[network].filtering_groups, [mode]: groups.map((group) => group.name) },
      },
    });
    this.notifyChanged();
  };

  handle_ModifyInspected = (network, obj) => {
    const newState = Object.assign({}, this.state);
    if (obj["signatures"]) {
      newState[network].mitm_inclusions.signatures = obj["signatures"];
    }
    if (obj["objects"]) {
      newState[network].mitm_inclusions.website_objects = obj["objects"];
    }
    if (obj["websites"]) {
      newState[network].mitm_inclusions.websites = obj["websites"];
    }
    this.setState(newState);
    this.notifyChanged();
  };

  handle_ChangedMitmExcludeGroups = (network, groups) => {
    this.setState({ ...this.state, [network]: { ...this.state[network], mitm_exclude_groups: groups.map((group) => group.name) } });
    this.notifyChanged();
  };

  count__Mitm_Inclusions = (mitm_inclusions) => {
    let len = mitm_inclusions.websites.length + mitm_inclusions.signatures.length + mitm_inclusions.website_objects.length;
    return len;
  };

  validate__Config = () => {
    const newState = Object.assign({}, this.state);
    newState.on_network_config_valid = true;
    newState.off_network_config_valid = true;

    /* Check on_network config */
    if (newState.on_network.mitm_enabled && this.count__Mitm_Inclusions(newState.on_network.mitm_inclusions) === 0) {
      newState.on_network_config_valid = false;
    }

    /* Check off_network config */
    if (newState.off_network.mitm_enabled && this.count__Mitm_Inclusions(newState.off_network.mitm_inclusions) === 0) {
      newState.off_network_config_valid = false;
    }
    this.setState(newState);
    return newState.on_network_config_valid && newState.off_network_config_valid;
  };

  handle_Submit = (event) => {
    event.preventDefault();

    if (this.validate__Config()) {
      let object = {
        on_network: this.state.on_network,
        off_network: this.state.off_network,
        exclude_domains: this.state.exclude_domains,
        auto_set_policies: this.state.auto_set_policies,
      };
      this.handle__SaveObject(object);
    } else {
      this.notifyChanged();
    }
  };

  handle__SaveObject = (object) => {
    this.setState({ changes: true, saving: true });

    Api.post(
      "/config/ajax/fzagentconfig",
      object,
      (e) => {
        this.handle_load();
        this.setState({
          changes: false,
          saving: false,
        });
      },
      (error) => {
        console.log(error);
        this.setState({ saving: false });
      }
    );
  };
}
